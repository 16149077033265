import { getStripePublicKey } from '../../../common-ui';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';

let stripePromise: Promise<Stripe>;

export function getStripe(): Promise<Stripe> {
  if (!stripePromise) {
    const stripePublicKey = getStripePublicKey();
    stripePromise = loadStripe(stripePublicKey) as Promise<Stripe>;
  }
  return stripePromise;
}

export async function getStripeElements(): Promise<StripeElements> {
  let stripe = await getStripe();
  return stripe.elements();
}
