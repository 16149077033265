export enum FEATURES {
  NONE = 'none', // special case
  METRICS = 'metrics',
  BETA = 'beta',
  MULTICLIENT = 'multiclient',
  PRAXISREPORT = 'praxisreport',
  DEBUG = 'debug',
  RECHNUNGEN_ERTRAGCOLUMS = 'rechnungenertragcolums',
  DEPRECATED = 'deprecated',
  // r4c
  FACTORING = 'factoring',
  FACTORINGEWE = 'factoringewe',
  DOCUMENTS_SMALL = 'documents_small',
  DOCUMENTS_BIG = 'documents_big',
  DOCUMENTS_FLAT = 'documents_flat',
  ANAMNESE_WHITE = 'anamnese_white',
  ANAMNESE_RED = 'anamnese_red',
  ANAMNESE_BLACK = 'anamnese_black',
  ANAMNESE_DIAMOND = 'anamnese_diamond',
  ANAMNESE_EXTRA_QUESTIONNAIRE = 'anamnese_extra_questionnaire',

  // SPECIALS
  EXTRAFACTORINGSCHNITTSTELLE = 'extrafactoringschnittstelle',
  DATAWAREHOUSE25 = 'datawarehouse25',
  R2CSV = 'r2csv',
  FACTORINGPREFETCHER = 'factoringprefetcher',
  DOCTOSYNC = 'doctosync',
  DSO = 'dso',
}

export const FACTORINGFEATURES = [FEATURES.FACTORING, FEATURES.FACTORINGEWE, FEATURES.FACTORINGPREFETCHER];

export const DOCUMENTSFEATURES = [FEATURES.DOCUMENTS_SMALL, FEATURES.DOCUMENTS_BIG, FEATURES.DOCUMENTS_FLAT];

export const ANAMNESEFEATURES = [
  FEATURES.ANAMNESE_WHITE,
  FEATURES.ANAMNESE_RED,
  FEATURES.ANAMNESE_BLACK,
  FEATURES.ANAMNESE_DIAMOND,
];

export const R4CFEATURES = [FEATURES.FACTORING, FEATURES.FACTORINGEWE, ...DOCUMENTSFEATURES, ...ANAMNESEFEATURES];

export const ALLFEATURES = [
  FEATURES.METRICS,
  FEATURES.BETA,
  FEATURES.PRAXISREPORT,
  FEATURES.DEBUG,
  FEATURES.RECHNUNGEN_ERTRAGCOLUMS,
  FEATURES.MULTICLIENT,
  FEATURES.FACTORINGPREFETCHER,
  FEATURES.DATAWAREHOUSE25,
  FEATURES.DOCTOSYNC,
  FEATURES.DSO,
  ...R4CFEATURES,
];

const sortedFeatures = [...R4CFEATURES, FEATURES.METRICS];

export enum R4CAPP {
  FACTORING = 'factoring',
  DOCUMENTS = 'documents',
  ANAMNESE = 'anamnese',
  UNKNOWN = 'unknown',
}

export type ROSEAPP = R4CAPP | FEATURES.METRICS;

export const FEATURE_TO_APP: { [x: string]: ROSEAPP } = {
  [FEATURES.FACTORING]: R4CAPP.FACTORING,
  [FEATURES.FACTORINGEWE]: R4CAPP.FACTORING,
  [FEATURES.DOCUMENTS_SMALL]: R4CAPP.DOCUMENTS,
  [FEATURES.DOCUMENTS_BIG]: R4CAPP.DOCUMENTS,
  [FEATURES.DOCUMENTS_FLAT]: R4CAPP.DOCUMENTS,
  [FEATURES.ANAMNESE_WHITE]: R4CAPP.ANAMNESE,
  [FEATURES.ANAMNESE_RED]: R4CAPP.ANAMNESE,
  [FEATURES.ANAMNESE_BLACK]: R4CAPP.ANAMNESE,
  [FEATURES.ANAMNESE_DIAMOND]: R4CAPP.ANAMNESE,
  [FEATURES.METRICS]: FEATURES.METRICS,
  [FEATURES.DATAWAREHOUSE25]: FEATURES.METRICS,
  [FEATURES.FACTORINGPREFETCHER]: FEATURES.METRICS,
  [FEATURES.DOCTOSYNC]: FEATURES.METRICS,
  [FEATURES.NONE]: R4CAPP.UNKNOWN,
};

export enum ROSEPRODUCTS {
  R4C = 'r4c',
  METRICS = 'metrics',
  UNKNOWN = 'unknown',
}

export const FEATURE_TO_PRODUCT: { [x: string]: ROSEPRODUCTS } = {
  [FEATURES.FACTORING]: ROSEPRODUCTS.R4C,
  [FEATURES.FACTORINGEWE]: ROSEPRODUCTS.R4C,
  [FEATURES.DOCUMENTS_SMALL]: ROSEPRODUCTS.R4C,
  [FEATURES.DOCUMENTS_BIG]: ROSEPRODUCTS.R4C,
  [FEATURES.DOCUMENTS_FLAT]: ROSEPRODUCTS.R4C,
  [FEATURES.ANAMNESE_WHITE]: ROSEPRODUCTS.R4C,
  [FEATURES.ANAMNESE_RED]: ROSEPRODUCTS.R4C,
  [FEATURES.ANAMNESE_BLACK]: ROSEPRODUCTS.R4C,
  [FEATURES.ANAMNESE_DIAMOND]: ROSEPRODUCTS.R4C,
  [FEATURES.METRICS]: ROSEPRODUCTS.METRICS,
  [FEATURES.DATAWAREHOUSE25]: ROSEPRODUCTS.METRICS,
  [FEATURES.FACTORINGPREFETCHER]: ROSEPRODUCTS.METRICS,
  [FEATURES.DOCTOSYNC]: ROSEPRODUCTS.METRICS,
  [FEATURES.NONE]: ROSEPRODUCTS.UNKNOWN,
};

export interface IFeatureShortItem {
  key: FEATURES;
  long: string;
  short: string;
  color: string;
  textColor: string;
}

export interface IFeatureShort {
  [feature: string]: IFeatureShortItem;
}

export const ShortFeaturesMap: IFeatureShort = {
  [FEATURES.METRICS]: { key: FEATURES.METRICS, long: 'metrics', short: 'm', color: '#319ee1', textColor: 'white' },
  [FEATURES.FACTORING]: {
    key: FEATURES.FACTORING,
    long: 'factoring basic',
    short: 'f',
    color: '#ffdc00',
    textColor: 'black',
  },
  [FEATURES.FACTORINGEWE]: {
    key: FEATURES.FACTORINGEWE,
    long: 'factoring ewe',
    short: 'F',
    color: '#DE9F64',
    textColor: 'black',
  },
  [FEATURES.FACTORINGPREFETCHER]: {
    key: FEATURES.FACTORINGPREFETCHER,
    long: 'factoring prefetcher',
    short: 'fp',
    color: '#DE9F64',
    textColor: 'black',
  },
  [FEATURES.DOCUMENTS_SMALL]: {
    key: FEATURES.DOCUMENTS_SMALL,
    long: 'documents small',
    short: 'd',
    color: '#e48385',
    textColor: 'white',
  },
  [FEATURES.DOCUMENTS_BIG]: {
    key: FEATURES.DOCUMENTS_BIG,
    long: 'documents big',
    short: 'D',
    color: '#e48385',
    textColor: 'white',
  },
  [FEATURES.DOCUMENTS_FLAT]: {
    key: FEATURES.DOCUMENTS_FLAT,
    long: 'docuemtns flat',
    short: 'D*',
    color: '#e48385',
    textColor: 'white',
  },
  [FEATURES.ANAMNESE_WHITE]: {
    key: FEATURES.ANAMNESE_WHITE,
    long: 'ana white',
    short: 'a',
    color: '#A864DE',
    textColor: 'white',
  },
  [FEATURES.ANAMNESE_RED]: {
    key: FEATURES.ANAMNESE_RED,
    long: 'ana red',
    short: 'a*',
    color: '#A864DE',
    textColor: 'white',
  },
  [FEATURES.ANAMNESE_BLACK]: {
    key: FEATURES.ANAMNESE_BLACK,
    long: 'ana black',
    short: 'A',
    color: '#A864DE',
    textColor: 'white',
  },
  [FEATURES.ANAMNESE_DIAMOND]: {
    key: FEATURES.ANAMNESE_DIAMOND,
    long: 'ana diamond',
    short: 'A*',
    color: '#A864DE',
    textColor: 'white',
  },
  [FEATURES.DOCTOSYNC]: {
    key: FEATURES.DOCTOSYNC,
    long: 'doctosync',
    short: 'L',
    color: '#428bca',
    textColor: 'black',
  },
  [FEATURES.DATAWAREHOUSE25]: {
    key: FEATURES.DATAWAREHOUSE25,
    long: 'data-ware-house',
    short: 'dwh',
    color: '#111',
    textColor: 'white',
  },
  [FEATURES.DSO]: { key: FEATURES.DSO, long: 'dental service org', short: 'dso', color: '#aaa', textColor: 'white' },
  [FEATURES.BETA]: { key: FEATURES.BETA, long: 'beta', short: 'beta', color: '#f24738', textColor: 'white' },
};

/**
 * @returns a positive number if feature b is an upgrade of feature a
 */
export function compareFeatures(a?: FEATURES, b?: FEATURES): number {
  if (!b) {
    return -1;
  }
  if (!a) {
    return 1;
  }
  return sortedFeatures.indexOf(b) - sortedFeatures.indexOf(a);
}

export enum PACKAGES {
  NONE = 'none',
  // old style
  DEMO = 'demo',
  BETA = 'BETA',
  BLACK = 'Black',
  RED = 'Red',
  WHITE = 'White',
  DIAMOND = 'Diamond',
  // new STYLE
  CHARLYANALYTICS = 'CHARLYANALYTICS',
  ROSEMETRICS = 'ROSEMETRICS',
}

export const ALLPACKAGES = [
  PACKAGES.DEMO,
  PACKAGES.BETA,
  PACKAGES.BLACK,
  PACKAGES.RED,
  PACKAGES.WHITE,
  PACKAGES.DIAMOND,
  PACKAGES.CHARLYANALYTICS,
  PACKAGES.ROSEMETRICS,
];
