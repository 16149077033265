import { IRoseAGGridColumn, dateFormatter, euroFormatter } from '@rose/common-ui';
import { IRoseInvoice } from '@rose/types';

export function settingsInvoiceColumnsDefs(): IRoseAGGridColumn<IRoseInvoice>[] {
  return [
    {
      headerName: 'Rechnungen',
      field: 'number',
      sort: 'desc',
      sortable: true,
      flex: 1,
      resizable: true,
    },
    {
      headerName: 'Datum',
      field: 'created',
      valueFormatter: dateFormatter,
      sortable: true,
      resizable: true,
    },
    {
      headerName: 'Betrag',
      field: 'amount',
      valueFormatter: euroFormatter,
      sortable: true,
      resizable: true,
    },
    {
      headerName: 'Download',
      cellRenderer: 'DownloadCell',
      cellStyle: { justifyContent: 'center' },
      width: 100,
    },
  ];
}
