import { IRoseAGGridColumn, dateFormatter } from '@rose/common-ui';
import { IRoseSubscription } from '@rose/types';
import { map } from 'lodash';

export function settingsSubscriptionsColumnsDefs(): IRoseAGGridColumn<IRoseSubscription>[] {
  return [
    {
      headerName: 'Produkt',
      valueGetter: params => map(params.data?.items, product => product.priceNickname || product.price).join(', '),
      sort: 'asc',
      sortable: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Start',
      field: 'start',
      valueFormatter: dateFormatter,
      sortable: true,
      resizable: true,
      width: 200,
    },
    {
      headerName: 'Nächste Rechnung',
      field: 'periodend',
      valueFormatter: dateFormatter,
      sortable: true,
      resizable: true,
      width: 300,
    },
  ];
}
