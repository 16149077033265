import { defineComponent } from 'vue';
import { german } from '@rose/common-ui';
import { settingsInvoiceColumnsDefs } from '@/components/columns/settings/vertragInvoices';
export default defineComponent({
  props: {
    invoices: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      "default": false
    }
  },
  data: function data() {
    return {
      localeText: german,
      gridOptions: {},
      gridApi: {},
      gridColumnApi: {},
      columnDefs: {},
      rowClassRules: {},
      pendingZuordnungen: []
    };
  },
  watch: {
    loading: function loading(val) {
      if (val) {
        this.gridApi.showLoadingOverlay();
      } else {
        this.gridApi.hideOverlay();
      }
    }
  },
  beforeMount: function beforeMount() {
    this.columnDefs = settingsInvoiceColumnsDefs();
    this.gridOptions = {
      rowHeight: 32,
      defaultColDef: {
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        }
      },
      suppressRowClickSelection: true,
      suppressCellFocus: true
    };
  },
  methods: {
    onGridReady: function onGridReady(event) {
      this.gridApi = event.api;
      this.gridColumnApi = event.columnApi;
    },
    onCellClicked: function onCellClicked(event) {
      this.$log.debug('Cell clicked', event);
    }
  }
});